import React from "react";
import {Container, Row, Col} from "react-bootstrap";

import imgC from "../../../assets/image/team-messaging/team-communication-and-scheduling-app-for-construction-workers.png";

const Content = () => {
  return (
    <>
      {/* <!-- Content Area --> */}
      <div className="content-section2 pt-13 pt-lg-17 bg-default-6">
        <Container>
          <Row className="align-items-center justify-content-center">
            <Col lg="5" xs="10" className="order-2 order-lg-1">
              <div className="content-img mb-n20">
                <img className="w-100" src={imgC} alt="" />
              </div>
            </Col>
            <Col
              xs="10"
              lg="6"
              className="offset-lg-1 mt-9 mt-lg-0 px-xl-11 order-1 order-lg-2"
              data-aos="fade-left"
              data-aos-duration="700"
            >
              <div className="content-text pr-xl-13">
                <h2 className="title gr-text-4 mb-7">
                  Why use the Camelo team messaging app?
                </h2>
                <p className="gr-text-8 mb-0">
                  Thousands of team leaders have chosen Camelo. Try Camelo and see the difference yourself.
                </p>
              </div>
              <div className="content-widget  pr-xl-13 mt-9 mb-11 mb-lg-16">
                <div className="single-widget d-flex align-items-center py-5">
                  <div className="widget-icon text-primary mr-6 gr-text-8">
                    <i className="icon icon-check-simple"></i>
                  </div>
                  <h3 className="w-title gr-text-8 font-weight-bold mb-0">
                    Schedule and message your employees in just one app.
                  </h3>
                </div>
                <div className="single-widget d-flex align-items-center py-5">
                  <div className="widget-icon text-primary mr-6 gr-text-8">
                    <i className="icon icon-check-simple"></i>
                  </div>
                  <h3 className="w-title gr-text-8 font-weight-bold mb-0">
                    Save hours of time spent on unnecessary emails and messages.
                  </h3>
                </div>
                <div className="single-widget d-flex align-items-center py-5">
                  <div className="widget-icon text-primary mr-6 gr-text-8">
                    <i className="icon icon-check-simple"></i>
                  </div>
                  <h3 className="w-title gr-text-8 font-weight-bold mb-0">
                    Keep discussions transparent, on-topic, and accessible to everyone.
                  </h3>
                </div>
              </div>
            </Col>
          </Row>
        </Container>
      </div>
    </>
  );
};

export default Content;
