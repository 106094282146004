import React from "react";
import {Container, Row, Col} from "react-bootstrap";

const Fact = ({darkMode = true}) => {

  return (
    <>
      {/* <!-- Fact Area --> */}
      <div className={"fact-section py-12 pt-lg-20 pb-lg-17 bg-hover-green position-relative paperwapper"}>
        <Container>
          <Row className="justify-content-center">
            <Col xs="10">
              <div className="">
                <h3 className="gr-text-3 mr-9 open-quote">
                  With Camelo, my staff always know where they should be, when they should be there, and what they should be doing.
                </h3>
              </div>
            </Col>
          </Row>
        </Container>
      </div>
    </>
  );
};

export default Fact;
