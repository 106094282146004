import React from "react";
import SEO from "../../components/Layout/SEO";
import PageWrapper from "../../components/PageWrapper";

import Hero from "../../sections/camelo/workplace-communication/Hero";
import Content1 from "../../sections/camelo/workplace-communication/Content1";
import Content2 from "../../sections/camelo/workplace-communication/Content2";
import Content3 from "../../sections/camelo/workplace-communication/Content3";
import Features from "../../sections/camelo/workplace-communication/Features";
import Testimonial from "../../sections/camelo/workplace-communication/Testimonial";
import Faq from "../../sections/camelo/workplace-communication/Faq";
import Recap from "../../sections/camelo/workplace-communication/Recap";
import Fact from "../../sections/camelo/workplace-communication/Fact";
import CTA from "../../sections/camelo/workplace-communication/CTA";

const WorkplaceCommunicationPage = () => {
  return (
    <>
      <PageWrapper>
        <SEO
          title="Team Messaging Software | Team Communication App | Camelo"
          description="Communicate with your staff at any time, from anywhere. Discuss and
            cooperate effectively via chat channels, private conversations &
            instant notifications."
        ></SEO>
        <Hero />
        <Content1 />
        <Content2 />
        <Content3 />
        {/* <Testimonial /> */}
        <Recap />
        <Fact />
        <CTA />
        <Faq />
      </PageWrapper>
    </>
  );
};

export default WorkplaceCommunicationPage;
