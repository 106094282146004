import React, {useState} from "react";
import {Link} from "gatsby";
import {Container, Row, Col, Collapse} from "react-bootstrap";

const QA = ({id, question, children}) => {
  const [openItem, setOpenItem] = useState(false);

  return (
    <div className="border rounded-10 mb-3 bg-white overflow-hidden">
      <a
        className="mb-0 btn py-8 pl-9 pr-7 border-bottom-0 bg-white accordion-trigger arrow-icon w-100"
        id={"heading-question-" + id}
        aria-controls={"collapse-" + id}
        onClick={() => setOpenItem(!openItem)}
        aria-expanded={openItem}
      >
        <div
          className="gr-text-7 font-weight-bold text-left text-blackish-blue p-0 "
        >
          {question}
        </div>
      </a>
      <Collapse in={openItem}>
        <div>
          <div className="card-body gr-color-blackish-blue-opacity-7 pt-0 pl-9 pr-15 gr-text-9 pb-9">
            <div className="pt-6">
              {children}
            </div>
          </div>
        </div>
      </Collapse>
    </div>
  );
};


const Faq = () => {
  return (
    <>
      {/* <!-- Faq Area --> */}
      <div className="faq-section pb-12 pt-12 pb-lg-24 pt-lg-24">
        <Container>
          <Row className="justify-content-center">
            <Col xl="8" lg="9">
              <div className="px-md-12 text-center mb-9 mb-lg-18">
                <h2 className="title gr-text-3 mb-8">
                  Frequently Asked Questions
                  </h2>
              </div>
            </Col>
          </Row>

          <Row className="justify-content-center">
            <Col xl="9" lg="9">
              <div className="accordion" id="accordionExample">
                <QA id="1" question="What is a team messaging app?" >
                  A team messaging app, also known as a team chat app, is a messaging platform for teams. Team members can make announcements, share news, and send instant messages to individuals or groups.
                </QA>
                <QA id="2" question="What can I do with the messaging feature on Camelo scheduling app?" >
                  You can quickly make announcements of schedule changes to your whole team. Send messages to ask whether someone can cover for a shift. Create a specific channel to discuss a new team project. And more.
                </QA>
              </div>
              <div className="col-12 text-center pt-11">
                <p className="gr-text-9 gr-text-9 gr-text-color">
                  Haven’t got your answer?{" "}
                  <Link to="/contact" className="btn-link">
                    Contact our support now
                  </Link>
                </p>
              </div>
            </Col>
          </Row>
        </Container>

      </div>
    </>
  );
};

export default Faq;
