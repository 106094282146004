import React from "react";
import {Link} from "gatsby";
import {Container, Row, Col, Button} from "react-bootstrap";

// import imgHero from "../../assets/image/l1/png/l1-hero-img.png";
import imgHero from "../../../assets/image/team-messaging/team-messaging-for-shift-work.svg";

const Hero = () => {
  return (
    <>
      {/* <!-- Hero Area --> */}
      <div
        className="pb-15 pt-24 pt-lg-32 pb-lg-28 position-relative paperwapper"
        id="hero-area-animation"
        style={{backgroundColor: "#ffe01b"}}
      >
        <Container id="animation-area-1">
          <Row className="pb-xl-12 justify-content-center text-center">
            <Col
              data-aos="fade-up"
              data-aos-duration="750"
              data-aos-delay="100"
              xs="10"
              xl="9"
            >
              <h1 className="gr-text-3 font-weight-bold mb-8">
                A central hub for team communication and scheduling
              </h1>
              <h2
                className="gr-text-6 lead"
                data-aos="fade-up"
                data-aos-delay="400"
                data-aos-duration="900"
              >
                All team conversations are in one app. <br className="d-none d-xl-block" /> Organized, transparent, and accessible to everyone.
              </h2>
            </Col>
          </Row>
          <Row className="align-items-center justify-content-center">
            <Col
              xs="9"
              md="7"
              lg="5"
              className="align-self-sm-end order-lg-2"
            >
              <div
                className="hero-img image-group-p12 position-relative mb-9 mb-sm-15 mb-lg-0"
                data-aos="fade-left"
                data-aos-duration="750"
                data-aos-delay="500"
              >
                <img className="w-100" src={imgHero} alt="" />
              </div>
            </Col>
            <Col
              xs="10"
              lg="7"
              xl="7"
              className="order-lg-1"
              data-aos="fade-right"
              data-aos-duration="750"
              data-aos-delay="500"
            >
              <div className="hero-content section-title text-left">
                <p className="gr-text-9 gr-text-color pr-md-7">
                  <div className="d-flex align-items-center py-2">
                    <div className="gr-text-8 text-black mr-6 d-flex align-items-center">
                      <i className="icon icon-check-simple"></i>
                    </div>
                    <h3 className="gr-text-8 mb-0">
                      Keep employees updated, wherever they are
                    </h3>
                  </div>
                  <div className="d-flex align-items-center py-2">
                    <div className="gr-text-8 text-black mr-6 d-flex align-items-center">
                      <i className="icon icon-check-simple"></i>
                    </div>
                    <h3 className="gr-text-8 mb-0">
                      Send private messages or chat in groups
                    </h3>
                  </div>
                  <div className="d-flex align-items-center py-2">
                    <div className="gr-text-8 text-black mr-6 d-flex align-items-center">
                      <i className="icon icon-check-simple"></i>
                    </div>
                    <h3 className="gr-text-8 mb-0">
                      Discuss different subjects via topic-specific channels
                    </h3>
                  </div>
                  <div className="d-flex align-items-center py-2">
                    <div className="gr-text-8 text-black mr-6 d-flex align-items-center">
                      <i className="icon icon-check-simple"></i>
                    </div>
                    <h3 className="gr-text-8 mb-0">
                      Communicate schedule changes in seconds
                    </h3>
                  </div>
                </p>
              </div>
            </Col>
          </Row>
        </Container>
      </div>
    </>
  );
};

export default Hero;
