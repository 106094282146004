import React from "react";

import {Container, Row, Col} from "react-bootstrap";

import imgC from "../../../assets/image/team-messaging/shifts-schedule-and-message-staff-app.png"

const Content = () => {
  return (
    <>
      {/* <!-- Content Area --> */}
      <div className="content-section2 pt-12 pb-5 pt-lg-19 pb-lg-23 bg-default-1">
        <Container>
          <Row className="align-items-center justify-content-center">
            <Col xs="10" lg="6" className="mb-10 mb-lg-0 order-2 order-lg-1">
              <div
                className="content-img img-group-2"
                data-aos="fade-right"
                data-aos-easing="ease-in-out-quart"
                data-aos-duration="750"
              >
                <img className="img-fluid" src={imgC} alt="Schedule and Messaging in just one app" />
              </div>
            </Col>
            <Col xs="10" lg="6" xl="5" className="order-1 order-lg-2">
              <h2 className="gr-text-4 mb-6">No more hopping between spreadsheets, emails, and chat apps</h2>
              <p className="gr-text-8 pb-12">
                You can now schedule and message your team in just one app.
                No more manual spreadsheets.
                No more endless scrolling through distracting email chains.
                No more messy chat apps where important information gets buried.
              </p>
            </Col>
          </Row>
        </Container>
      </div>
    </>
  );
};

export default Content;
