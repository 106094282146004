import React from "react";
import {Link} from "gatsby";
import {Container, Row, Col, Button} from "react-bootstrap";

import imgC from "../../../assets/image/team-messaging/annoucements.png";

const Content = () => {
  return (
    <>
      {/* <!-- Content Area --> */}
      <div className="content-section pt-12 pb-13 pt-lg-21 pb-lg-22 ">
        <Container>
          <Row className="align-items-center justify-content-center">
            <Col xs="10" lg="6" className="pr-lg-9">
              <div className="content-text">
                <h2 className="gr-text-4 mb-8">
                  The team messaging app that keeps everyone in sync
                </h2>
                <p className="gr-text-8 pb-12">
                  Camelo is built to connect team members and keep your team in sync.
                </p>
                <div className="content-widget-1">
                  <div className="media single-widget mb-7">
                    <div className="count d-none d-lg-flex circle-sm gr-bg-blue-opacity-1 mr-8">
                      <span className="text-primary--light-only gr-text-9">
                        1
                      </span>
                    </div>
                    <div className="media-body">
                      <h3 className="gr-text-7">Announcements</h3>
                      <p className="gr-text-9">
                        Make announcements that reach 100% of your workforce and keep everyone on the same page.
                      </p>
                    </div>
                  </div>
                  <div className="media single-widget mb-7">
                    <div className="count d-none d-lg-flex circle-sm gr-bg-blue-opacity-1 mr-8">
                      <span className="text-primary--light-only gr-text-9">
                        2
                      </span>
                    </div>
                    <div className="media-body">
                      <h3 className="gr-text-7">Channels</h3>
                      <p className="gr-text-9">
                        Organize specific discussions by project, topic, group, and team. Channels can be private or public. Public channels give everyone an overview of what's happening.
                      </p>
                    </div>
                  </div>
                  <div className="media single-widget mb-7">
                    <div className="count d-none d-lg-flex circle-sm gr-bg-blue-opacity-1 mr-8">
                      <span className="text-primary--light-only gr-text-9">
                        3
                      </span>
                    </div>
                    <div className="media-body">
                      <h3 className="gr-text-7">Messages</h3>
                      <p className="gr-text-9">
                        Chat privately with a team member, or socialize in groups. Quickly ask if someone can cover for a shift, or explain why you can't approve a time-off request.
                      </p>
                    </div>
                  </div>
                  <div className="media single-widget mb-7">
                    <div className="count d-none d-lg-flex circle-sm gr-bg-blue-opacity-1 mr-8">
                      <span className="text-primary--light-only gr-text-9">
                        4
                      </span>
                    </div>
                    <div className="media-body">
                      <h3 className="gr-text-7">Notifications</h3>
                      <p className="gr-text-9">
                        Notifications of new messages, shifts, and tasks are sent to each employee’s inbox instantly.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </Col>
            <Col xs="10" lg="6" className="mt-10 mt-lg-0">
              <div
                className="content-img img-group-1"
                data-aos="fade-left"
                data-aos-easing="ease-in-out-quart"
                data-aos-duration="750"
              >
                <img className="img-fluid" src={imgC} alt="The team messaging app that keeps everyone in sync" />
              </div>
            </Col>
          </Row>
        </Container>
      </div>
    </>
  );
};

export default Content;
